import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//services
import { AuthHttpService } from './authhttp.service';


@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  public endpoint: string = "restaurant";

  constructor(
    public _authhttp: AuthHttpService
  ) { }

  /**
   * return staff lists
   * @param page
   * @returns
   */
  list(page: number = 1, query: string = ''): Observable<any> {
    const url = `${this.endpoint}/list?keyword=${query}&page=${page}`;
    return this._authhttp.getRaw(url);
  }

  /**
   * view detail page
   */
  view(): Observable<any> {
    return this._authhttp.get(`${this.endpoint}/detail`);
  }

  /**
   * update account
   * @param params
   */
  update(params: any): Observable<any> {
    return this._authhttp.patch(`${this.endpoint}`, params);
  }
}
