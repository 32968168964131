import { ApplicationRef, Component } from '@angular/core';
import { AlertController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
//services
import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { TranslateLabelService } from './services/translate-label.service';
import { LanguageService } from './services/language.service';

//const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public updatesAvailable = false;

  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Tickets', url: '/support', icon: 'mail' },
    { title: 'Create Ticket', url: '/ticket-form', icon: 'paper-plane' }
  ];
 
  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
   // public updates: SwUpdate,
    public appRef: ApplicationRef,
    private platform: Platform,
    private eventService: EventService,
    public auth: Auth0Service,
    public translateService: TranslateLabelService,
    public languageService: LanguageService,
    public authService: AuthService
  ) {}

  ngOnInit() {

    window.onpopstate = e => {

      if (window['history-back-from'] == 'onDidDismiss') {
        window['history-back-from'] = null;
        return false;
      }

      this.popoverCtrl.getTop().then(overlay => {

        if (overlay) {
          this.popoverCtrl.dismiss({
            'from': 'native-back-btn'
          });
        }

        this.modalCtrl.getTop().then(overlay => {

          if (overlay) {
            this.modalCtrl.dismiss({
              'from': 'native-back-btn'
            });
          }
        });
      });
    };

    this.platform.ready().then(() => {

      /*if (this.platform.is('hybrid')) {
        SplashScreen.hide();
      }*/

     // this.setServiceWorker();
 
     GoogleAuth.initialize({
      //clientId: '876118421973-gs2r5han4mftkf3kjdbvnf1uegvt89b3.apps.googleusercontent.com',
      clientId: '876118421973-bhut8gnlo51u6loreh8oi6drhvotocap.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: false,
    })

     const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if(urlParams.get('auth_key')) {
        this.authService.loginByKey(urlParams.get('auth_key'));
      }

      /**
       * todo: need to test in mobile app
       * when user comming back from auth0
       */
      this.auth.isAuthenticated$.subscribe(isAuthenticated => {

        if(!isAuthenticated || this.authService.isLogged) return null;

        //this.auth.idTokenClaims$.subscribe(r => {
        this.auth.getAccessTokenSilently().subscribe(r => {
          this.authService.useTokenForAuth(r).then();
        });
      });   
    });

    // Check for network connection
    this.eventService.internetOffline$.subscribe(async () => {
      const alert = await this.alertCtrl.create({
        header: 'No Internet Connection',
        subHeader: 'Sorry, no Internet connectivity detected. Please reconnect and try again.',
        buttons: ['Dismiss']
      });
      alert.present();
      this.navCtrl.navigateRoot(['/no-internet']);
    });

    this.eventService.errorStorage$.subscribe(() => {
      this.navCtrl.navigateRoot(['app-error']);
    });

    // On Login Event, set root to Internal app page
    this.eventService.userLogined$.subscribe(userEventData => {
      this.navCtrl.navigateRoot(['/support']);
    });

    this.eventService.error500$.subscribe(userEventData => {
      this.navCtrl.navigateRoot(['/server-error']);
    });

    this.eventService.error404$.subscribe(userEventData => {
      this.navCtrl.navigateRoot(['/not-found']);
    });

    this.eventService.accountAssignmentRemoved$.subscribe(userEventData => {
      this.navCtrl.navigateRoot(['/server-error']);
    });

    // On Logout Event, set root to Login Page
    this.eventService.userLoggedOut$.subscribe((logoutReason) => {
      // Set root to Login Page
      this.navCtrl.navigateRoot(['/login']);

      // Show Message explaining logout reason if there's one set
      if (logoutReason) {
        console.log(logoutReason);
      }

      /*this.auth.isAuthenticated$.subscribe(isAuthenticated => {
        if(isAuthenticated) {
          this.auth.logout({ returnTo: document.location.origin });
        }
      });*/
    });
    
    /**
     * Save user language preference after login
     */
    this.eventService.setLanguagePref$.subscribe(language_pref => {

      /**
       * changing status on `side` property change
       * https://github.com/ionic-team/ionic/blob/master/core/src/components/menu/menu.tsx
       *
      */

      this.languageService.listToTranslate().subscribe(languages => {

        for (const element of languages) {
          if (element.code == language_pref) {
            // change language

            this.translateTo(element);

            break;
          }
        }
      });
    });

  }

  /**
   * keep checking for service worker update
   *
   setServiceWorker() {

    // service worker watcher

    if (
      !this.platform.is('capacitor') && 'serviceWorker' in navigator &&
      environment.serviceWorker &&
      window.location.hostname != 'localhost'
    ) {

      navigator.serviceWorker.register('./ngsw-worker.js');

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const updateInterval$ = interval(60 * 1000); // every minute
      const updateIntervalOnceAppIsStable$ = concat(appIsStable$, updateInterval$);

      updateIntervalOnceAppIsStable$.subscribe(() => {
        this.updates.checkForUpdate().then((e) => {
          console.log('checking for update');
        });
      });

      this.updates.available.subscribe((e) => {
        console.log('updates available');
        this.updatesAvailable = true;
      });

      this.updates.activated.subscribe((e) => {
        console.log('update activated');
        this.updatesAvailable = false;
      }, reason => {
        console.error('service worker update activation failed', reason);
      });
    }
  }*/

  /**
   * When user select refresh on udpate available prompt
   *
  onUpdateAlertRefresh() {

    if (!this.updatesAvailable) {
      return this.updatesAvailable = false;
    }

    try {
      this.updates.activateUpdate().then(() => {
      });
    } catch {
    }

    window.location.reload();
  }*/

  /**
   * When user select close on udpate available prompt
   */
  onUpdateAlertClose() {
    this.updatesAvailable = false;
  }
  
  /**
   * Change app language
   * @param language
   */
  translateTo(language) {

    this.translateService.use(language.code).subscribe();

    /*if(this.authService.isLogged && this.authService.language_pref != language.code)
    {
      this.accountService.updateLanguagePref(language.code).subscribe();
    }*/

    this.authService.setLanguagePref(language);

    if (language.code == 'ar') {
      document.querySelector('html').setAttribute('dir', 'rtl');
      document.querySelector('body').setAttribute('class', 'rtl');
    } else {
      document.querySelector('html').setAttribute('dir', 'ltr');
      document.querySelector('body').setAttribute('class', 'ltr');
    }
  }

  /**
   * change language 
   */
  changeLanguage(event) {
    console.log(event);
    this.eventService.setLanguagePref$.next(event.target.value);
  }
}
