import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//services
import { TranslateLabelService } from 'src/app/services/translate-label.service';
import {RestaurantService} from "../../../../services/logged-in/restaurant.service";


@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.page.html',
  styleUrls: ['./restaurant.page.scss'],
})
export class RestaurantPage implements OnInit {

  public restaurants = [];

  public currentPage = 1;
  public totalPages = 0;
  public totalCount = 0;

  public search: string = "";

  public loading: boolean = false;

  public borderLimit: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public translateService: TranslateLabelService,
    public restaurantService: RestaurantService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  /**
   * load customers
   */
  loadData() {
    this.loading = true;

    this.restaurantService.list(this.currentPage, this.search).subscribe(response => {

      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));

      this.restaurants = response.body;
    },
    error => this.loading = false,
    () => this.loading = false,
    );
  }

  /**
   * load more on scroll to bottom
   * @param event
   */
  doInfinite(event) {

    if(this.currentPage >= this.totalPages) {

      if(event && event.target)
        event.target.complete();
      return null;
    }

    this.loading = true;

    this.currentPage++;

    this.restaurantService.list(this.currentPage, this.search).subscribe(response => {

      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));

      this.restaurants = this.restaurants.concat(response.body);

      if(event && event.target)
        event.target.complete();

    }, err => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  /**
   * search by query
   */
  filter() {
    this.currentPage = 1;
    this.loadData();
  }

  selectRestaurant($event, restaurant) {
    this.dismiss({
      restaurant
    });
  }

  onBackButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dismiss();
  }

  /**
   * dismiss popup
   * @param data
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss(data);
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
