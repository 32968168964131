import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, take, retryWhen } from 'rxjs/operators';
import { genericRetryStrategy } from '../../util/genericRetryStrategy';
import { saveAs } from 'file-saver';
// service
import { EventService } from '../event.service';
import { AuthService } from '../auth.service';
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {

  constructor(
    private _http: HttpClient,
    public _auth: AuthService,
    public _alertCtrl: AlertController,
    public translationService: TranslateService,
    public eventService: EventService,
  ) { }

  /**
   * Requests via PDF GET verb
   * @param {string} endpointUrl
   * @param {string} filename
   * @returns {Observable<any>}
   */
  pdfget(endpointUrl: string, filename: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl; 
    return this.download(url, 'application/pdf', filename);    
  }
  
  /**
   * Requests via GET verb
   * @param {string} endpointUrl
   * @param {number} invoice_id
   * @returns {Observable<any>}
   */
   excelget(endpointUrl: string, filename: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;    
    return this.download(url, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filename);    
  }

  /**
   * save file from given url 
   * @param url 
   * @param type 
   * @param filename 
   * @returns 
   */
  download(url, type, filename): Observable<any> {

    const headers = this._buildAuthHeaders();

    return this._http.get(url, {
      responseType: 'blob',
      headers: headers
    }).pipe(
      //retryWhen(genericRetryStrategy()),
      catchError((err) => {
        return this._handleError(err);
      }),
      map((response) => { // download file
        var blob = new Blob([response], { type: type });
        //file name to dowanload/generate invoice
        saveAs(blob, filename);
      })
    );
  }

  /**
   * Requests via GET verb
   * @param {string} endpointUrl
   * @returns {Observable<any>}
   */
  getRaw(endpointUrl: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const headers = this._buildAuthHeaders();
    // https://www.techiediaries.com/angular-httpclient-headers-full-response/
    return this._http.get(url, { headers: headers, observe: 'response' })
      .pipe(
       // retryWhen(genericRetryStrategy()),
       // catchError((err) => this._handleError(err)),
       // take(1),
        map((res: HttpResponse<any>) => { return res; })
      );
  }

  /**
   * Requests via GET verb
   * @param {string} endpointUrl
   * @returns {Observable<any>}
   */
  get(endpointUrl: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const headers = this._buildAuthHeaders();

    return this._http.get(url, { headers: headers })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res: HttpResponse<any>) => { return res })
      );
  }

  /**
   * Requests via POST verb
   * @param endpointUrl
   * @param params
   * @param withHeader
   */
  post(endpointUrl: string, params: any, withHeader: boolean = false): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const headers = this._buildAuthHeaders();
    let responseHeader = { headers: headers, observe: 'response' };
    return this._http.post(url, JSON.stringify(params), { headers: headers, observe: 'response' })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res: HttpResponse<any>) => {
          return (withHeader) ? res : res.body;
        })
      );
  }

  /**
   * Requests via PATCH verb
   * @param {string} endpointUrl
   * @param {*} params
   * @returns {Observable<any>}
   */
  patch(endpointUrl: string, params: any): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const headers = this._buildAuthHeaders();

    return this._http.patch(url, JSON.stringify(params), { headers })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res: HttpResponse<any>) => res)
      );
  }

 /**
   * Requests via PATCH verb
   * @param {string} endpointUrl
   * @param {*} params
   * @returns {Observable<any>}
   */
  put(endpointUrl: string, params: any): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const headers = this._buildAuthHeaders();

    return this._http.put(url, JSON.stringify(params), { headers: headers })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res: HttpResponse<any>) => { return res })
      );
  }

  /**
   * upload file
   * @param endpointUrl
   * @param formData
   */
  uploadFile(endpointUrl, formData): Observable<any> {

    const url = environment.apiEndpoint + endpointUrl;

    // Get Bearer Token from Auth Service
    const bearerToken = this._auth.getAccessToken();

    // Build Headers with Bearer Token
    // headers.append('Content-Type', 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2));
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + bearerToken,
      'Accept': 'application/json',
      'enctype': 'multipart/form-data'
    });

    return this._http.post(url, formData, { headers: headers })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        map((res: HttpResponse<any>) => { return res })
      );
  }

  /**
   * Requests via DELETE verb. Params should be a part of the url string
   * similar to get requests.
   * @param {string} endpointUrl
   * @returns {Observable<any>}
   */
  delete(endpointUrl: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const headers = this._buildAuthHeaders();

    return this._http.delete(url, { headers: headers })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res: HttpResponse<any>) => { return res })
      );
  }

  /**
   * Build the Auth Headers for All Verb Requests
   * @returns {Headers}
   */
  _buildAuthHeaders() {
    const bearerToken = this._auth.getAccessToken();

    // Build Headers with Bearer Token
    return new HttpHeaders({
      Authorization: 'Bearer ' + bearerToken,
      'Content-Type': 'application/json',
      Language: (this.translationService.currentLang) ? this.translationService.currentLang : 'en',
    });
  }

  /**
   * Handles Caught Errors from All Authorized Requests Made to Server
   * @returns {Observable}
   */
  private _handleError(error: any): Observable<any> {

    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';

    // Handle Bad Requests
    // This error usually appears when agent attempts to handle an
    // account that he's been removed from assigning
    if (error.status === 400) {
      // this.eventService.accountAssignmentRemoved$.next();
      this._auth.logout('Session expired, please log back in.');
      return EMPTY;
    }

    // Handle No Internet Connection Error

    if (error.status === 0 || error.status === 504) {
      // if(!navigator.onLine)
      this.eventService.internetOffline$.next();
      return EMPTY;
    }

    // Handle Expired Session Error
    if (error.status === 401) {
      this._auth.logout('Session expired, please log back in.');
      return EMPTY;
    }

    // Handle internal server error - 500
    if (error.status === 500) {
      this.eventService.error500$.next();
      return EMPTY;
    }

    // Handle page not found - 404 error
    if (error.status === 404) {
      this.eventService.error404$.next();
      return EMPTY;
    }

    console.error('Error: ' + errMsg);

    return throwError(errMsg);
  }
}
