import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SelectiveLoadingStrategy } from './util/SelectiveLoadingStrategy';
import { environment } from '../environments/environment';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import localeAr from '@angular/common/locales/ar-KW';
//services
import { AuthService } from './services/auth.service';
import { SentryErrorhandlerService } from './services/sentry.errorhandler.service';
//components
import { UpdateAlertModule } from './components/update-alert/update-alert.module';
//pages
// import { TicketFormPageModule } from './pages/logged-in/support/ticket-form/ticket-form.module';
import { StaffPageModule } from './pages/logged-in/pickers/staff/staff.module';
import {RestaurantPageModule} from "./pages/logged-in/pickers/restaurant/restaurant.module";
import { AuthModule } from '@auth0/auth0-angular';
import { registerLocaleData } from '@angular/common';


export function startupServiceFactory(authService) {
  return () => authService.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

declare global {
  interface Window { analytics: any; }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AuthModule.forRoot({
      domain: 'bawes.us.auth0.com',
      clientId: 'zBLi5rqikntjIFqS4iJY7RQx6445yf5w'
    }),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    // TicketFormPageModule,
    CKEditorModule,
    HttpClientModule,
    UpdateAlertModule,
    StaffPageModule,
    RestaurantPageModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.serviceWorker}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    File,
    FileChooser,
    FilePath,
    IOSFilePicker,
    //AndroidPermissions,
    //MediaCapture,
    //OneSignal,
    FileOpener,
    FileTransfer,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AuthService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SelectiveLoadingStrategy,
    //   SwUpdate,
    { provide: ErrorHandler, useClass: SentryErrorhandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  static injector: Injector;

  constructor(public injector: Injector) {
    AppModule.injector = injector;
    registerLocaleData(localeAr, 'ar');
  }
}
