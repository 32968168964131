import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//services
import { StaffService } from 'src/app/services/logged-in/staff.service';
import { TranslateLabelService } from 'src/app/services/translate-label.service';


@Component({
  selector: 'app-staff',
  templateUrl: './staff.page.html',
  styleUrls: ['./staff.page.scss'],
})
export class StaffPage implements OnInit {

  public staffs = [];

  public currentPage = 1;
  public totalPages = 0;
  public totalCount = 0;

  public search: string = "";

  public loading: boolean = false;

  public borderLimit: boolean = false; 

  constructor(
    public modalCtrl: ModalController,
    public translateService: TranslateLabelService,
    public staffService: StaffService
  ) { }

  ngOnInit() {
    this.loadData();
  }
  
  /**
   * load customers
   */
  loadData() {
    this.loading = true;

    this.staffService. list(this.currentPage, this.search).subscribe(response => {

      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));
      
      this.staffs = response.body;
    },
    error => this.loading = false,
    () => this.loading = false,
    );
  } 

  /**
   * load more on scroll to bottom
   * @param event 
   */
  doInfinite(event) {

    if(this.currentPage >= this.totalPages) {
      
      if(event && event.target)
        event.target.complete();
      return null;
    }
    
    this.loading = true;

    this.currentPage++;

    this.staffService.list(this.currentPage, this.search).subscribe(response => {
      
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));
      this.totalPages = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.totalCount = parseInt(response.headers.get('X-Pagination-Total-Count'));

      this.staffs = this.staffs.concat(response.body);

      if(event && event.target)
        event.target.complete();

    }, err => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
  
  /**
   * return name initial for profile photo placeholder 
   */
  getInitials(staff) {
    const name = staff.staff_name;
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  /**
   * search by query
   */
  filter() {
    this.currentPage = 1;
    this.loadData();
  }

  /**
   * dismiss/close with staff details
   * @param staff 
   */
  onSelect(staff) {
    this.dismiss({
      staff: staff
    });
  }

  onBackButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dismiss();
  }

  /**
   * dismiss popup
   * @param data 
   */
  dismiss(data = {}) {
    this.modalCtrl.getTop().then(o => {
      if(o) {
        o.dismiss(data);
      }
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 25);
  }
}
