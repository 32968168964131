import { Injectable, RendererFactory2 } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, first, map, retryWhen, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { genericRetryStrategy } from '../util/genericRetryStrategy';
import { environment } from '../../environments/environment';
import { Storage } from '@capacitor/storage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// service
import { EventService } from './event.service';
import { TranslateLabelService } from './translate-label.service';
import { AlertController, LoadingController } from '@ionic/angular';


declare var navigator;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  public audioPlayer: HTMLAudioElement = new Audio();

  public showPlayButton = true;

  public ringBell = '1';

  public renderer;

  public _accessToken;
  public id: number;
  public staff_name: string;
  public staff_email: string;

  public role: number;

  public theme: string;

  public navEnable = true;

  public isLogged = false;

  public displayCookieMessage: any = '0';

  public showOneSignalPrompt = true;

  public language = {
    code: 'en',
    name: 'English',
  };

  // guest can have language_pref

  public language_pref: string;

  public totalPendingOrders: number = 0;

  public setToSilenceMode: boolean = false;

  public auth0Token;

  private _urlBasicAuth = 'auth/login';
  public _urlLoginAuth0 = 'auth/login-auth0';
  private _urlUpdatePass = 'auth/update-password';
  private _urlResetPassRequest = 'auth/request-reset-password';

  public _urlresendVerificationEmail = 'auth/resend-verification-email';
  public _urlUpdateEmail = 'auth/update-email';
  public _urlIsEmailVerified = 'auth/is-email-verified';
  public _urlVerifyEmail = 'auth/verify-email';
  public _urlLoginByKey = 'auth/login-by-key';
  public _urlLoginByGoogle = 'auth/login-by-google';

  constructor(
    public _http: HttpClient,
    public router: Router,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public eventService: EventService,
    public translate: TranslateLabelService,
    public rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    //this.audioPlayer.setAttribute('playsinline', "true");
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    /**
     * new router changes don't wait for startup service
     * https://github.com/angular/angular/issues/14615
     */
    return new Promise(resolve => {

      this.navEnable = true;

      if (route.data.navDisable) {
        this.navEnable = false;
      }

      if (this.isLogged) {
        resolve(true);
      }

      Storage.get({
        key: 'ringBell',
      }).then(ret => {
      });

      Storage.get({ key: 'loggedInStaff' }).then(ret => {

        const user = JSON.parse(ret.value);

        if (user && user.token) {
          this.isLogged = true;
          this._accessToken = user.token;
          this.id = user.id;
          this.staff_email = user.staff_email;
          this.staff_name = user.staff_name;
          this.role = user.role;
          
          // set language pref if user have valid language code saved in DB

          if (user.language_pref) {
            this.eventService.setLanguagePref$.next(user.language_pref);
          }

          resolve(true);
        } else {
          resolve(false);

          this.logout('invalid access');
        }

      }).catch(r => {
        this.eventService.errorStorage$.next({ error: r });
      });
    });
  }

  /**
   * Set language pref for current user
   **/
  public setLanguagePref(language) {
    Storage.set({ key: 'language', value: JSON.stringify(language) })
      .catch((r) => {
      // this.eventService.errorStorage$.next({ error: r });
    });

    this.language = language;

    this.language_pref = language.code;

    if (this._accessToken) {
      this.saveInStorage();
    }
  }


  /**
   * Login by Google for mobile app
   */
  loginByGoogle() {

    GoogleAuth.signIn().then(async googleUser => {
 
      if (googleUser && googleUser.authentication && googleUser.authentication.idToken) {
        this.useGoogleIdTokenForAuth(googleUser.authentication.idToken, false);
      } else {
        this.eventService.googleLoginFinished$.next({});

        const alert = await this.alertCtrl.create({
          message: this.translate.transform('Error getting login by Google+ API'), // JSON.stringify(err)
          buttons: [this.translate.transform('Ok')]
        });
        await alert.present();
      }
    }).catch(async err => {

      console.error(err);

      this.eventService.googleLoginFinished$.next({});

      if (err = 'popup_closed_by_user') {
        return false;
      }
      
      const alert = await this.alertCtrl.create({
        message: this.translate.transform('Error getting login by Google+ API'), // JSON.stringify(err)
        buttons: [this.translate.transform('Ok')]
      });
      await alert.present();

    }); 
  }

  /**
   * Login by google idToken
   */
  async useGoogleIdTokenForAuth(idToken, showLoader = true) {

    let loading;

    if (showLoader) {
      loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: this.translate.transform('Logging in...')
      });
      loading.present();
    }

    const url = environment.apiEndpoint + this._urlLoginByGoogle;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Language: this.translate.currentLang
    });
    
    return this._http.post(url, {
      idToken: idToken,
    }, {
      headers: headers
    })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        first(),
        map((res) => res)
      )
      .subscribe(async response => {

        if (response.operation == 'success') {

          this.setAccessToken(response, true); 

        } else if (response.operation == 'error') {

          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Error getting login by Google+ API'), // JSON.stringify(err)
            buttons: [this.translate.transform('Ok')]
          });
          await alert.present();

        }

        this.eventService.googleLoginFinished$.next({});

      }, err => {

        this.eventService.googleLoginFinished$.next(err);
      },
      () => {
        if (loading) {
          loading.dismiss();
        }
      });
  }

  /**
   * set app theme
   * @param theme
   */
  setTheme(theme) {

    Storage.set({
      key: 'theme',
      value: theme
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.theme = theme;

    if (theme == 'night') {
      this.renderer.removeClass(document.body, 'day');
      this.renderer.addClass(document.body, 'night');
    } else {
      this.renderer.addClass(document.body, 'day');
      this.renderer.removeClass(document.body, 'night');
    }
  }

  /**
   * Save user data in storage
   */
  saveInStorage() {
    Storage.set({
      key: 'loggedInStaff',
      value: JSON.stringify({
        token: this._accessToken,
        id: this.id,
        staff_name: this.staff_name,
        role: this.role,
        staff_email: this.staff_email,
        language_pref: this.language_pref,
      })
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });
  }

  startTheBell() {

    this.setStartBell();

    this.audioPlayer.muted = false;

    //this.totalPendingOrders > 0 &&

    if (!this.audioPlayer.played) {
      this.ringTheBell();
    }

    /*else {
      this.audioPlayer.pause();
      this.showPlayButton = false;
    }*/
  }

  setStartBell() {

    this.ringBell = '1';

    Storage.set({
      key: 'ringBell',
      value: '1'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.showPlayButton = false;
  }

  stopStartBell() {
    Storage.set({
      key: 'ringBell',
      value: '0'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.ringBell = '0';

    this.showPlayButton = true;
  }

  async getBell() {
    try {
      let { value } = await Storage.get({ key: 'ringBell' });

      //if not selected

      if(value == null) {
        value = '1';
      }

      return this.setBell(value);
    } catch (error) {
      this.eventService.errorStorage$.next({ error: error });
    }
  }

  setBell(value) {
    this.ringBell = value;
    return value;
  }

  async stopTheBell() {
    await this.stopStartBell();
    this.audioPlayer.muted = true;
    this.audioPlayer.pause();
  }

  /**
   * check permission
   */
  checkSoundPermission() {

    this.audioPlayer.volume = 0;
    this.audioPlayer.muted = true;
    const promise = this.audioPlayer.play();

    if (promise !== undefined) {
      promise.then(_ => {

        this.showPlayButton = false;

      }).catch(error => {

        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.

        this.showPlayButton = true;
      });
    }
  }

  ringTheBell() {


    Storage.set({
      key: 'ringBell',
      value: '1'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    this.ringBell = '1';
    // if (!this.ringBell || this.ringBell == '0') {
    //   this.stopTheBell();
    //   return;
    // }

    const promise = this.audioPlayer.play();
    console.log(promise);

    if (promise !== undefined) {
      promise.then(_ => {
        // Autoplay started!

        this.audioPlayer.volume = 1;
        this.audioPlayer.muted = false;

        this.showPlayButton = false;

      }).catch(error => {

        console.error(error);

        // Autoplay was prevented.
        // Show a "Play" button so that user can start playback.

        this.showPlayButton = true;
      });
    }
  }

  /**
   * Logs a user out by setting logged in to false and clearing token from storage
   * @param {string} [reason]
   * @param {boolean} [silent]
   */
  logout(reason?: string, silent = false) {

    this.stopTheBell();

    this.isLogged = false;

    // Remove from Storage then process logout

    this._accessToken = null;
    this.id = null;
    this.staff_name = null;
    this.role = null;
    this.staff_email = null;

    Storage.clear().catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    if (!silent) {
      this.eventService.userLoggedOut$.next(reason ? reason : false);
    }

    Storage.set({
      key: 'cookieMessageWasApproved',
      value: (this.displayCookieMessage == '0') ? '1' : '0'
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });
  }

  /**
   * Set the access token
   */
  setAccessToken(response, redirect = false) {

    this._accessToken = response.token;
    this.id = response.id;
    this.staff_name = response.staff_name;
    this.staff_email = response.staff_email;

    this.role = response.role;

    this.language_pref = response.language_pref;

    // Save to Storage

    this.saveInStorage();

    // set language pref if user have valid language code saved in DB

    if (response.language_pref) {
      this.eventService.setLanguagePref$.next(response.language_pref);
    }

    if (this._accessToken) {
      this.isLogged = true;
      this.eventService.userLogined$.next({ redirect });
    }
  }

  /**
   * Set initial config
   */
  async load() {

    const promises = [
      Storage.get({ key: 'loggedInStaff' }),
      Storage.get({ key: 'language' })
    ];

    return Promise.all(promises)
      .then(data => {

        this.getBell();

        if (data[1] && data[1].value && typeof data[1].value == "string") {
          try {
            this.language = JSON.parse(data[1].value);
          }catch {

          }

        } else {

          const browserLanguage = navigator.languages
              ? navigator.languages[0]
              : (navigator.language || navigator.userLanguage);

          if (browserLanguage && browserLanguage.indexOf('en') > -1) {
            this.language = {
              code: 'en',
              name: 'English'
            };
          } else {
            this.language = {
              name: 'عربى',
              code: 'ar'
            };
          }
        }

        console.log(this.language);
        
        // for guest use language value in storage, for login user loggedInStaff.language_pref

        const loggedInUser = data[0]? JSON.parse(data[0].value): null;

        if (loggedInUser && loggedInUser.language_pref) {
          this.language = loggedInUser.language_pref == 'ar' ? {
            name: 'عربى',
            code: 'ar'
          }: {
            code: 'en',
            name: 'English'
          };
        }

        this.translate.setDefaultLang('en');

        this.translate.use(this.language.code);

        document.getElementsByTagName('html')[0].setAttribute('dir', (this.language.code == 'ar') ? 'rtl' : 'ltr');
        if (loggedInUser) {
          this.setAccessToken(loggedInUser);
        }

        /*else if (this.cookieService.get('otp')) {
          this._platform.ready().then(_ => {
            setTimeout(() => {
              this.loginByOtp(this.cookieService.get('otp'));
            }, 800);//to fix: https://www.pivotaltracker.com/story/show/168368025
          });
        }*/

        // set direction based on language
        // this._platform.setDir('rtl', true);
        document.documentElement.dir = (this.language.code == 'ar') ? 'rtl' : 'ltr';

      })
      .then(r => {
        // this.eventService.errorStorage$.next({ error: r });
      });

    /*Storage.get({ key: 'cookieMessageWasApproved' }).then(ret => {

        if (ret.value) {
          this.setTheme(ret.value);
        }
      }).catch(r => {
        this.eventService.errorStorage$.next({ error: r });
      });*/
    }

  /**
   * Get Access Token from Service or Cookie
   * @returns {string} token
   */
  getAccessToken(redirect = false) {

    // Return Access Token if set already
    if (this._accessToken) {
      return this._accessToken;
    }

    Storage.get({ key: 'loggedInStaff' }).then(ret => {

      const user = JSON.parse(ret.value);

      if (user) {
        this.setAccessToken(user, redirect);
      }
    }).catch(r => {
      this.eventService.errorStorage$.next({ error: r });
    });

    return this._accessToken;
  }


  /**
   * @param auth_key 
   * @param store_id 
   * @returns 
   */
  async loginByKey(auth_key: string, store_id = null) {
    
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: this.translate.transform('Logging in...')
    });
    loading.present();

    const url = environment.apiEndpoint + this._urlLoginByKey;

    const headers = this._buildAuthHeaders();

    return this._http.post(url, {
      auth_key: auth_key,
      store_id: store_id
    }, {
      headers
    })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        first(),
        map((res) => res)
      )
      .subscribe(async response => {

        if (response.operation == 'success') {

          this.setAccessToken(response, true);

        } else if (response.operation == 'error') {
          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Error getting login'), // JSON.stringify(err)
            buttons: [this.translate.transform('Okay')]
          });
          await alert.present();

        }

        //this.eventService.googleLoginFinished$.next({});

      }, err => {

        //this.eventService.googleLoginFinished$.next(err);
      },
      () => {
        if (loading) {
          loading.dismiss();
        }
      });
  }

  /**
   * Login by Auth0 accessToken
   */
  async useTokenForAuth(accessToken, showLoader = true) {

    let loading;

    if (showLoader) {
      loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: this.translate.transform('Logging in...')
      });
      loading.present();
    }

    const url = environment.apiEndpoint + this._urlLoginAuth0;

    const headers = this._buildAuthHeaders();

    return this._http.post(url, {
      accessToken: accessToken,
    }, {
      headers: headers
    })
      .pipe(
        retryWhen(genericRetryStrategy()),
        catchError((err) => this._handleError(err)),
        first(),
        map((res) => res)
      )
      .subscribe(async response => {

        if (response.operation == 'success') {

          this.setAccessToken(response, true);

        } else if (response.code == 1) {

          //this.auth0Token = accessToken;

          //this.router.navigate(['register']);

          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Account not found!'), // JSON.stringify(err)
            buttons: [this.translate.transform('Ok')]
          });
          await alert.present();

        } else if (response.operation == 'error') {

          const alert = await this.alertCtrl.create({
            message: this.translate.transform('Error getting login by Auth0 API'), // JSON.stringify(err)
            buttons: [this.translate.transform('Ok')]
          });
          await alert.present();

        }

        //this.eventService.googleLoginFinished$.next({});

      }, err => {

        //this.eventService.googleLoginFinished$.next(err);
      },
      () => {
        if (loading) {
          loading.dismiss();
        }
      });
  }

  /**
   * Basic auth, exchanges access details for a bearer access token to use in
   * subsequent requests.
   * @param  {string} email
   * @param  {string} password
   */
  basicAuth(email: string, password: string): Observable<any> {
    // Add Basic Auth Header with Base64 encoded email and password
    const authHeader = new HttpHeaders({
      Authorization: 'Basic ' + btoa(unescape(encodeURIComponent(`${email}:${password}`))),
      Language: this.language_pref || 'en',
    });
    const url = environment.apiEndpoint + this._urlBasicAuth;

    return this._http.get(url, {
      headers: authHeader,
    }).pipe(
      retryWhen(genericRetryStrategy()),
      first(),
      map((res: HttpResponse<any>) => res)
    );
  }

  /**
   * json to string error message
   * @param message
   */
  errorMessage(message): string {

    if (message.length) {
      return message + '';
    }

    const a = [];

    for (const i in message) {

      if (!Array.isArray(message[i])) {
        a.push(message[i]);
        continue;
      }

      for (const j of message[i]) {
        a.push(j);
      }
    }

    return a.join('<br />');
  }

  /**
   * reset password request
   * @param email
   */
  resetPasswordRequest(email: string) {
    const url = environment.apiEndpoint + this._urlResetPassRequest;
    return this._http.post(url, { email }, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Verify email
   * @param email
   * @param code
   */
   verifyEmail(email: string, code: string) {
    const url = environment.apiEndpoint + this._urlVerifyEmail;
    const headers = this._buildAuthHeaders();
    return this._http.post(url, { email: email, 'code': code }, { headers: headers }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Check if email already verified
   * @param res
   */
  isAlreadyVerified(res): Observable<any> {
    const url = environment.apiEndpoint + this._urlIsEmailVerified;
    return this._http.post(url, res, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Resend verification email
   * @param email
   */
  resendVerificationEmail(email: string) {
    const url = environment.apiEndpoint + this._urlresendVerificationEmail;
    const headers = this._buildAuthHeaders();
    return this._http.post(url, { 'email': email }, { headers: headers }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Update email address
   * @param params params
   */
  updateEmail(params: any): Observable<any> {
    const url = environment.apiEndpoint + this._urlUpdateEmail;
    return this._http.post(url, params, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  /**
   * Update password by password reset token
   * @param token
   * @param newPassword
   */
  updatePassword(newPassword: string, token: string): Observable<any> {
    return this._http.patch(environment.apiEndpoint + this._urlUpdatePass, {
      newPassword,
      token
    }, { headers: this._buildAuthHeaders() }).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((err) => this._handleError(err)),
      first(),
      map((res) => res)
    );
  }

  _buildAuthHeaders() {
    return new HttpHeaders({
      Language: this.language_pref || 'en',
      'Content-Type': 'application/json'
    });
  }

  /**
   * Handles Caught Errors from All Authorized Requests Made to Server
   * @returns {Observable}
   */
  public _handleError(error: any): Observable<any> {

    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';

    // Handle Bad Requests
    // This error usually appears when agent attempts to handle an
    // account that he's been removed from assigning
    if (error.status === 400) {
      this.eventService.errorStorage$.next({ error: error });
      return EMPTY;
    }

    // Handle No Internet Connection Error /service worker timeout

    if (error.status === 0 || error.status === 504) {
      this.eventService.internetOffline$.next();
      return EMPTY;
    }

    if (!navigator.onLine) {
      this.eventService.internetOffline$.next();
      return EMPTY;
    }

    // Handle Expired Session Error
    if (error.status === 401) {
      this.logout('Session expired, please log back in.');
      return EMPTY;
    }

    // Handle internal server error - 500
    if (error.status === 500) {
      this.eventService.error500$.next();
      return EMPTY;
    }

    // Handle page not found - 404 error
    if (error.status === 404) {
      this.eventService.error404$.next();
      return EMPTY;
    }
    console.log('Error: ' + errMsg);

    return throwError(errMsg);
  }
}
