
export const environment = {
    envName: 'dev',
    production: false,
    serviceWorker: true,
    apiEndpoint: 'https://crmapi.dev.plugn.io/v1/',
    cookieDomain: 'crm.staging.plugn.io',
    tempBucketUrl: 'plugn-public-anyone-can-upload-24hr-expiry.s3.amazonaws.com/',
    permanentBucketUrl: 'plugn-uploads-dev-server.s3.amazonaws.com/',
    cloudinaryUrl: 'https://res.cloudinary.com/plugn/image/upload/c_scale,h_60,w_60/restaurants/',
  };
