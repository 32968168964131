import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//services
import { AuthService } from './services/auth.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/start-pages/login/login.module').then(m => m.LoginPageModule),
  },/*
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/start-pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/logged-in/account/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canActivate: [AuthService],
  },*/
  {
    path: 'no-internet',
    loadChildren: () => import('./pages/errors/no-internet/no-internet.module').then((m) => m.NoInternetPageModule),
    data: {
      name: 'NoInternetPage',
    },
  },
  {
    path: 'server-error',
    loadChildren: () => import('./pages/errors/server-error/server-error.module').then((m) => m.ServerErrorPageModule),
    data: {
      name: 'ServerErrorPage',
    },
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/errors/not-found/not-found.module').then((m) => m.NotFoundPageModule),
    data: {
      name: 'NotFoundPage',
    },
  },
  {
    path: 'app-error',
    loadChildren: () => import('./pages/errors/app-error/app-error.module').then((m) => m.AppErrorPageModule),
  },/*
  {
    path: 'update-password',
    loadChildren: () => import('./pages/start-pages/update-password/update-password.module').then(m => m.UpdatePasswordPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/start-pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule),
    //canActivate: [AuthGuard]
  },*/
  {
    path: 'support',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-list/ticket-list.module').then( m => m.TicketListPageModule)
  },
  {
    path: 'ticket-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-view/ticket-view.module').then( m => m.TicketViewPageModule)
  },
  {
    path: 'ticket-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-form/ticket-form.module').then( m => m.TicketFormPageModule)
  },

  {
    path: 'staff',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/pickers/staff/staff.module').then( m => m.StaffPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
